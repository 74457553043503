import React from 'react'
// import Header from '../components/header'
import Layout from '../components/layout'
import styles from './projects.module.css'
import { graphql, Link } from "gatsby"

export default function MyProjects({data}){
    return (
      <Layout>
        {/* <Header headerText={data.site.siteMetadata.title} /> */}
        {/* <Header headerText="Recent Projects:" /> */}
        <h1 className={styles.postTitle}>Recent Projects:</h1>
        <hr />
        {/* <p>{data.allMarkdownRemark.totalCount} Projects</p> */}
        <div className={styles.projectsBody} style={{ marginBottom: "10%" }}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id}>
              <Link to={node.fields.slug} className={styles.link}>
                <h2
                  style={{ textDecoration: "underline" }}
                  className={styles.postTitle}
                >
                  {node.frontmatter.title}
                </h2>
                <p className={styles.byline}>
                  <small>
                    Created by: {node.frontmatter.author} on{" "}
                    {node.frontmatter.date}
                  </small>
                </p>
                <p className={styles.description}>{node.frontmatter.description}</p>
                <img src={node.frontmatter.img} alt={node.frontmatter.title} />
              </Link>
            </div>
          ))}
        </div>
      </Layout>
    )
}

export const query = graphql`
  query myQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            author
            date
            description
            title
            img
          }
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`